import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div id="asm-page-404" className="container py-5">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-6 text-center">
          <h1 className="msg-404 text-blue-beta text-300">404</h1>
          <h1 className="display-4 mb-4">Página no encontrada</h1>
          <p className="h5 mb-4">
            <span className="h4 text-blue-alpha">¡Ups!</span> parece que la
            página que estás buscando no existe!
          </p>
          <p>¿Quizás estabas buscando una de estas?</p>
          <ul>
            <li>
              <Link to="/">Página Principal</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <Link to="/noticias">Noticias</Link>
            </li>
            <li>
              <Link to="/events">Eventos</Link>
            </li>
          </ul>
          <p>
            Si es un problema conocido puedo contactar a nuestro equipo de{" "}
            <Link to="/">Soporte.</Link>
          </p>
          <p className="border-left pl-2 text-left">
            Si estaba intentando acceder a otra página, tal vez pueda
            encontrarla a <Link to="/sitemap">continuación</Link>.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
